


















































import { createPostController } from '@/modules/common/dialogs/create-post/create-post-controller'
import { Observer } from 'mobx-vue'
import { Component, Vue, Watch, Provide, Ref } from 'vue-property-decorator'
import { PostsViewModel } from '@/modules/recruitment/daos/viewmodels/posts-viewmodel'

@Observer
@Component({
  components: {
    'rct-post-list-item': () => import('@/modules/recruitment/posts/rct-post-list-item.vue'),
    'rct-post-pin-list': () => import('@/modules/recruitment/posts/rct-post-pin-list.vue'),
    observer: () => import('vue-intersection-observer'),
  },
})
export default class PostsSection extends Vue {
  @Provide() vm = new PostsViewModel()
  createPostController = createPostController

  @Watch('$route.query', { immediate: true }) queryChange(query: { tab: string }) {
    if (query) {
      this.vm.fetchPostsByFilter({ ...query, _limit: 9 })
    }
  }

  loadMore() {
    if (this.vm?.daoDetailPostsCanLoadMore) {
      this.vm.loadMore()
    }
  }

  beforeDestroy() {
    this.vm.destroy()
  }
}
