import { appProvider } from '@/app-provider'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { CommunityModel } from '@/models/community-model'
import { ProfileModel } from '@/models/profile-model'
import { recruitmentPostController } from '@/modules/recruitment/dialogs/recruitment-post-controller'
import { postController } from '@/modules/common/dialogs/post/post-controller'
import { apiService } from '@/services/api-services'
import { dispatcher } from '@/stores/dispatcher'
import { PostStore } from '@/stores/post-store'
import { PostsStore } from '@/stores/posts-store'
import { walletStore } from '@/stores/wallet-store'
import { map, omit } from 'lodash'
import { action, computed, IReactionDisposer, observable, reaction, when } from 'mobx'
import { asyncAction } from 'mobx-utils'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { recruitmentStore } from '@/stores/recruitment-store'

const Tabs = [
  { title: 'All post', queryParam: 'all-post' },
  { title: 'Find My team', queryParam: 'find_my_team' },
  { title: 'Find Job', queryParam: 'find_job' },
  { title: 'Find Hobby Buddy', queryParam: 'find_hobby_buddy' },
]

const SortBys = [
  { title: 'Latest', queryParam: 'latest' },
  { title: 'Top', queryParam: 'top' },
]

type Tab = {
  title?: string
  queryParam?: string
}

type SortBy = {
  title?: string
  queryParam?: string
}

export type FilterDaoDetail = {
  tab?: Tab
  sortBy?: SortBy
}

const defaultFilter = {
  tab: Tabs[0],
  sortBy: SortBys[0],
}

export class PostsViewModel {
  @observable postsStore?: PostsStore
  @observable communityProfile?: CommunityModel = undefined
  @observable communityId = process.env.VUE_APP_RECRUITMENT_ID as any
  @observable filters = {} as any
  @observable contributors?: ProfileModel[] = []
  @observable searchTextInput = ''
  @observable searchPostLoading = false
  @observable isShowPostBySearch = false
  @observable searchedPosts: PostStore[] = []

  @observable daoPosts: PostStore[] = []
  @observable daoDetailFilter = {}
  @observable postFetching = false

  @observable pinedPosts: PostStore[] = []
  @observable tabFilter = [
    { title: 'All post', query: 'all-post' },
    { title: 'Find My team', query: 'find_my_team' },
    { title: 'Find Job', query: 'find_job' },
    { title: 'Find Hobby Buddy', query: 'find_hobby_buddy' },
  ]
  @observable selectedTab = this.tabFilter[0]
  @observable loadingMore = false
  @observable loadMoreState = { _start: 0, _limit: 9 }
  @observable loadingData = false
  @observable isImageDAOLoading = false
  @observable pinLoading = false

  private _disposers: IReactionDisposer[]
  private _unsubcrible = new Subject()

  @computed get postStores() {
    return this.postsStore?.posts
  }

  constructor() {
    this.daoDetailFilter = defaultFilter
    this._disposers = [
      reaction(
        () => recruitmentPostController.completedUpdateType,
        (type) => {
          if (type) {
            recruitmentPostController.changeCompletedUpdateType(undefined)
            const query = appProvider.router.currentRoute.query
            this.fetchPostsByFilter({ ...query, _limit: 9, _start: 0 } as any)
          }
        }
      ),
      reaction(
        () => postController.completeUpdateType,
        (type) => {
          if (type) {
            postController.changeCompleteUpdateType(undefined)
            const query = appProvider.router.currentRoute.query
            this.fetchPostsByFilter({ ...query, _limit: 9, _start: 0 } as any)
          }
        }
      ),
    ]

    dispatcher.$profileChanged.pipe(takeUntil(this._unsubcrible)).subscribe((profile) => {
      recruitmentStore.syncFollowProfile(profile)
    })
    dispatcher.$postChanged.pipe(takeUntil(this._unsubcrible)).subscribe((postAction) => {
      recruitmentStore.syncPostAction(postAction)
    })

    this.loadData()
  }

  @action.bound onTabChange(tab: string) {
    const selectedTab = this.tabFilter.find((item) => item.title === tab)
    if (selectedTab) {
      this.selectedTab = selectedTab
      const currentQuery = appProvider.router.currentRoute.query
      appProvider.router.replace({ query: { ...currentQuery, tab: selectedTab.query } })
    }
  }

  @asyncAction *fetchPostsByFilter(query: { tab?: string; _limit?: number; _start?: number }) {
    try {
      this.postFetching = true
      const targetQuery = omit(query, ['tab'])
      if (['find_my_team', 'find_job', 'find_hobby_buddy'].includes(query.tab || '')) {
        targetQuery['recruitment_type'] = query.tab
      }
      const [daoPosts] = yield Promise.all([recruitmentStore.fetchPostsByFilter(targetQuery), this.fetchPinedPosts()])
      this.daoPosts = daoPosts.map((item) => new PostStore(item)) || []

      this.syncPinStatus(this.daoPosts)
      this.loadMoreState = { _start: 0, _limit: 9 }
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.postFetching = false
    }
  }

  @computed get pinedPostId() {
    return map(this.pinedPosts, 'post.id') || []
  }

  @action syncPinStatus(postStores: PostStore[]) {
    postStores.map((postStore) => {
      if (this.pinedPostId.includes(postStore.post.id)) postStore.isPinded = true
    })
  }

  @asyncAction *loadMore() {
    try {
      return
      this.loadingMore = true
      const query = appProvider.router.currentRoute.query
      this.loadMoreState = {
        ...query,
        _start: this.loadMoreState._start + this.loadMoreState._limit,
        _limit: this.loadMoreState._limit,
      }
      const postMore = yield recruitmentStore.fetchPostsByFilter(this.loadMoreState)
      const newPost = postMore.map((post) => new PostStore(post))
      this.syncPinStatus(newPost)
      this.daoPosts = [...this.daoPosts, ...newPost]
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.loadingMore = false
    }
  }

  @action destroy() {
    this._unsubcrible.next()
    this._unsubcrible.complete()
    this._disposers.forEach((d) => d())
    this.postsStore?.destroy()
    this.searchedPosts.forEach((postStore) => postStore.destroy())
  }

  @asyncAction *loadData() {
    try {
      this.loadingData = true
      this.postFetching = true
      yield when(() => recruitmentStore.recruitmentLoaded)
      this.communityProfile = recruitmentStore.recruitmentDao
      this.contributors = recruitmentStore.contributors
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.loadingData = false
      setInterval(() => {
        this.searchPostLoading = false
      }, 2000)
    }
  }

  @asyncAction *fetchPinedPosts() {
    try {
      const res = yield apiService.daos.fetchPinnedPosts(this.communityId)
      this.pinedPosts = res.map((post) => {
        const postStore = new PostStore(post)
        postStore.isPinded = true
        return postStore
      })
    } catch (e) {
      snackController.commonError(e)
    }
  }

  @asyncAction *changePinPost(postStore: PostStore, forceCheck = false) {
    try {
      this.pinLoading = true
      const postId = postStore.post.id
      if (!postId) return
      postStore.pinLoading = true
      if (!postStore.isPinded) yield apiService.daos.pinPost(this.communityId, { postId })
      else yield apiService.daos.unPinPost(this.communityId, { postId })
      postStore.togglePinMode()
      this.fetchPinedPosts()
      if (forceCheck) {
        const targetPostStore = this.daoPosts?.find((postStore) => postStore?.post?.id === postId)
        if (targetPostStore) targetPostStore.isPinded = postStore.isPinded
      }
    } catch (e) {
      snackController.commonError(e)
    } finally {
      postStore.pinLoading = false
      this.pinLoading = false
    }
  }

  @asyncAction *searchPost() {
    if (!this.searchTextInput || this.searchTextInput.trim().length === 0) {
      this.isShowPostBySearch = false
      return
    }
    if (!this.communityId) return
    try {
      this.postFetching = true
      const posts = yield apiService.daos.searchPost(this.searchTextInput, this.communityId)
      if (posts && posts.length) {
        this.searchedPosts = posts.map((item) => new PostStore(item))
      } else this.searchedPosts = []
      this.isShowPostBySearch = true
    } catch (e) {
      snackController.commonError(e)
    } finally {
      this.postFetching = false
    }
  }

  @asyncAction *followUser(item: any) {
    try {
      item.loading = true
      yield apiService.userFollows.followUser({ follow: item.id })
      item.isFollowing = true
    } catch (error) {
      snackController.commonError(error)
    } finally {
      item.loading = false
    }
  }

  @asyncAction *unFollowUser(item: any) {
    try {
      item.loading = true
      yield apiService.userFollows.unFollowUser({ follow: item.id })
      item.isFollowing = false
    } catch (error) {
      snackController.commonError(error)
    } finally {
      item.loading = false
    }
  }

  @computed get owner() {
    return this.communityProfile?.profile
  }

  @computed get isOwnerDao() {
    return this.owner && this.owner._id === walletStore.userProfile?._id
  }

  @computed get isContributorDao() {
    return map(this.contributors, 'id').includes(walletStore.userProfile?._id)
  }

  @computed get isAdmin() {
    return this.isOwnerDao || this.isContributorDao
  }

  @computed get tabs() {
    return this.tabFilter.map((tab) => tab.title)
  }

  @computed get tab() {
    return this.selectedTab.title
  }
  @computed get postsDisplayed() {
    return this.isShowPostBySearch ? this.searchedPosts : this.daoPosts?.filter((postStore) => !postStore.isPinded)
  }

  @computed get showEmptyData() {
    return this.isShowPostBySearch && !this.searchedPosts?.length && !this.searchPostLoading && !this.daoPosts?.length
  }
  @computed get totalDaoPosts() {
    return recruitmentStore.totalDaoPosts
  }
  @computed get daoDetailPostsCanLoadMore() {
    return this.postsDisplayed.length < this.totalDaoPosts
  }
}
